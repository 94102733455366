import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { StaticImage } from "gatsby-plugin-image"

import Typography from "src/components/Typography"
import * as styles from "./Form.module.scss"

import * as Joi from "joi"

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  name: Joi.string().required(),
  message: Joi.string().required(),
})

const FormStates = Object.freeze({
  init: "INIT",
  pending: "PENDING",
  error: "ERROR",
  success: "SUCCESS",
})

const getButtonProps = state => {
  switch (state) {
    default:
    case FormStates.init:
      return { text: "SEND", class: styles.Button }
    case FormStates.pending:
      return { text: "SENDING", class: "" }
    case FormStates.success:
      return { text: "SUCCESS", class: "" }
    case FormStates.error:
      return { text: "ERROR", class: "" }
  }
}

export function Form() {
  const { register, handleSubmit } = useForm()
  const [formState, setFormState] = useState(FormStates.init)
  const [errors, setErrors] = useState([])

  async function sendEmail(data) {
    console.log(data)
    try {
      await fetch(
        "https://blesu-contact-request.azurewebsites.net/api/request",
        { body: JSON.stringify(data), method: "POST" }
      )
      setFormState(FormStates.success)
    } catch (err) {
      console.error(err)
      setFormState(FormStates.error)
    }
  }

  const onSubmit = async data => {
    let errors = []
    try {
      await schema.validateAsync(data, { abortEarly: false })
      setErrors([])
      setFormState(FormStates.pending)
    } catch (err) {
      setErrors(err.details)
      errors = err.details
      console.error(err.details)
    } finally {
      sendEmail(data)
    }
  }

  const { text, class: buttonClass } = getButtonProps(formState)
  return (
    <section className={styles.SectionWrapper}>
      <div className={styles.ImageWrapper}>
        <StaticImage
          src="../../../../images/Chat3.png"
          alt="Message illustration"
          layout="constrained"
          placeholder="none"
          quality={100}
          className="animated-partner-logo"
          width={900}
        />
      </div>
      <div className={styles.FormWrapper}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1">Drop us a message</Typography>

          <div>
            <input
              {...register("name")}
              placeholder="Your name"
              type="text"
              className={
                errors.some(err => err.path[0] === "name")
                  ? styles.InputError
                  : styles.Input
              }
            ></input>
          </div>
          <div>
            <input
              {...register("email")}
              placeholder="Your email"
              type="email"
              className={
                errors.some(err => err.path[0] === "email")
                  ? styles.InputError
                  : styles.Input
              }
            ></input>
          </div>
          <div>
            <input
              {...register("message")}
              placeholder="Message..."
              type="text"
              className={
                errors.some(err => err.path[0] === "message")
                  ? styles.InputError
                  : styles.Input
              }
            ></input>
          </div>
          <button
            disabled={formState !== FormStates.init}
            type="submit"
            className={buttonClass}
          >
            {text}
          </button>
        </form>
      </div>
    </section>
  )
}
