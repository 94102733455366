import React, { useContext, useEffect } from "react"

import Layout from "src/components/Layout"
import Seo from "../components/seo"
import ThemeContext, { Themes } from "src/theme/context"
import Form from "src/components/Sections/Contact/Form"

const Contact = () => {
  const { changeTheme } = useContext(ThemeContext)
  useEffect(() => {
    changeTheme(Themes.default)
  }, [])
  return (
    <Layout>
      <Seo title="Contact" description="We build digital products." />
      <Form />
    </Layout>
  )
}

export default Contact
